<template>
    <input
      class="gavc-input gavc-input--icon"
      @input="$emit('input')"
      :id="id"
      type="text"
      :maxlength="maxLength"
      v-model.lazy="tempValue"
      v-money="maskedOptions"
      :disabled="disabled"
    >
</template>

<script>
export default {
  name: 'numberMoneyInput',
  props: {
    id: {
      type: String,
      default: "input"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: String,
      default: "10"
    },
    maskedOptions: {
      type: Object,
      required: true
    },
    value: {
      type: [Number, String],
      default: 0
    }
  },
  data: function() {
    return {
      tempValue: null
    }
  },
  mounted () {
    if (this.value !== 0) {
      this.tempValue = String(this.value)
    }
  },
  watch: {
    value(newValue) {
      if (this.value !== 0) {
        this.tempValue = String(newValue)
      }
    },
    tempValue(value) {
      this.$emit('value-changed', Number(value.replace(/ /g,'')))
    },
  },
  methods: {
    updateValue (newValue) {
      this.tempValue = newValue
    }
  }
}
</script>
